import React from 'react-dom'
import styles from './footer.module.scss'
import Link from 'next/link'
// import { reportError } from 'utils/monitoring'
// import LoadingUI from 'components/loading'
import getUri from 'lib/getUri'
// import { gql, useQuery } from '@apollo/client'
import { useStore } from 'hooks/locationContext'
import { Footer_HeaderItems_Entry, Footer_HeaderUpItems_Entry } from 'lib/generated/graphql-types'
import { formatUri } from 'lib/formatUri'

export type MainFooterProps = {
  footerData: Array<Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry> | undefined
}

export default function MainFooter({ footerData }: MainFooterProps): JSX.Element {
  const currentLocation = useStore()[0]
  const store = useStore()[0]
  if (!footerData) return null
  const { initialLocation: loc } = currentLocation
  // console.log('>>> MainFooter', footerData)
  const topBlockEntries = footerData?.filter((entry: any) => entry?.typeHandle === 'headerUpItems') || []
  const topLeftBlockEntries = ((topBlockEntries as any)[0]?.children || [])[0]
  const topRightBlockEntries = ((topBlockEntries as any)[1]?.children || [])[0]

  const leftTopLinkUI =
    (topLeftBlockEntries?.buttonLink || [])[0]?.url?.trim()?.length && topLeftBlockEntries?.buttonText?.trim()?.length ? (
      <Link href={formatUri(`/contact-us`, loc)}>
        <a className="yellow-button">
          <span>{topLeftBlockEntries?.buttonText}</span>
        </a>
      </Link>
    ) : (
      ''
    )

  const rightTopLinkUI =
    (topRightBlockEntries?.buttonLink || [])[0]?.url?.trim()?.length && topRightBlockEntries?.buttonText?.trim()?.length ? (
      <Link href={formatUri(`/display-homes`, loc)}>
        <a className="yellow-button">
          <span>{topRightBlockEntries?.buttonText}</span>
        </a>
      </Link>
    ) : (
      ''
    )

  const bottomBlockEntries = footerData.filter((entry: Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry) => {
    return entry?.typeHandle !== 'headerUpItems'
  }).filter((entry: Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry) => {
    return entry?.children?.length
  })

  const linksFromBlockUI = (entry: Footer_HeaderItems_Entry | Footer_HeaderUpItems_Entry) =>
    entry?.children?.map((child: any, index: any) => {
      const childClass = child?.lightswitch ? 'lightswitch' : ''
      const url = child?.internalLink?.length ? formatUri(child.internalLink[0].uri, loc) : child?.externalLink
      const isExternal = url && /^https?:\/\//.test(url)
      const showItem = url && child?.title

      return showItem ? (
        <li key={index} className={childClass}>
          <Link href={url || '/'}>
            <a target={isExternal ? '_blank' : undefined} rel={isExternal ? 'noopener noreferrer' : undefined}>
              {child?.title || ''}
            </a>
          </Link>
        </li>
      ) : null
    })

  const bottomBlockUI = bottomBlockEntries?.map((entry: any, index: any) => (
    <ul className={styles['footer__bottom-link']} key={index}>
      {linksFromBlockUI(entry)}
    </ul>
  ))

  // if (loading) return <LoadingUI />
  // if (error) reportError(error)
  // if (!data) return null

  return (
    <footer className={styles['footer']}>
      <div className="container">
        <div className={styles['footer__wrap']}>
          <div className={styles['footer__top']}>
            <div className={styles['footer__top-left']}>
              <div className={styles['footer__top-left-wrap']}>
                <span className={styles['footer__top-left-title']}>{topLeftBlockEntries?.title || ''}</span>
                <span className={styles['footer__top-left-text']}>{topLeftBlockEntries?.text || ''}</span>
              </div>
              {leftTopLinkUI}
            </div>
            <div className={styles['footer__top-right']}>
              <div className={styles['footer__top-right-wrap']}>
                <span className={styles['footer__top-right-title']}>{topRightBlockEntries?.title || ''}</span>
                <span className={styles['footer__top-right-text']}>{topRightBlockEntries?.text || ''}</span>
              </div>
              {rightTopLinkUI}
            </div>
          </div>
          <div className={styles['footer__bottom']}>{bottomBlockUI}</div>
        </div>
      </div>
    </footer>
  )
}
