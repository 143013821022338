import React from 'react-dom'
import styles from './footer.module.scss'
import Link from 'next/link'
import { useStore } from 'hooks/locationContext'
import getUri from 'lib/getUri'
import { Copyright_GlobalSet } from 'lib/generated/graphql-types'

export default function BottomFooter( bottomFooterData: Copyright_GlobalSet): JSX.Element {
  const store = useStore()[0]
  const currentLocation = useStore()[0]

  if(!bottomFooterData) return null

  let License = []
  let Links = []

  License = bottomFooterData.licensesArea?.map((link: any, index: any) => (
    <li className="yellow-vertical-line" key={index}>
      <span>{link.text}</span>
    </li>
  ))
  Links = bottomFooterData.legacyArea?.map((link: any, index: any) => (
    <li key={index}>
      {link?.licenseEntry?.[0]?.slug ? (
        <Link href={`${getUri(store.initialLocation)}/${link?.licenseEntry?.[0]?.slug}`}>
          <a>{link.text}</a>
        </Link>
      ) : (
        <Link href={link?.licenseLink}>
          <a>{link.text}</a>
        </Link>
      )}
    </li>
  ))


  return (
    <footer className={styles['footer']}>
      <div className="container">
        <div className={styles['footer__top']}>
          <div className={styles['footer__top-copyright']}>
            <span className={styles['footer__top-copyright-first']}>{bottomFooterData.header}</span>
          </div>
          <ul>{License}</ul>
        </div>
        <div className={styles['footer__bottom']}>
          <span>{bottomFooterData.subheader}</span>
          <ul>{Links}</ul>
        </div>
      </div>
    </footer>
  )
}
